<template>
  <div>
    <div class="deitor-box">
      <div ref="editor"></div>
      <el-dialog
        title="请选择图片"
        :visible.sync="showimageEdit"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :modal="false"
        width="80%"
        style="z-index: 10006"
      >
        <select-image
          @onSelectImage="onSelectImage"
          imagetype="more"
        ></select-image>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import E from "wangeditor";
import selectImage from "@/components/image/image.vue";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    meanArray: {
      // 自定义菜单
      type: Array,
      default: null,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    value: function (value) {
      if (value !== this.editor.txt.html()) {
        this.editor.txt.html(this.value);
      }
    },
    //value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
  },
  components: { selectImage },
  data() {
    return {
      showimageEdit: false,
      selectField: "",
      // 默认有这么多菜单，meanArray有值以meanArray为准
      defaultMeanus: [
        "head",
        "bold",
        "fontSize",
        "fontName",
        "italic",
        "underline",
        "strikeThrough",
        "indent",
        "lineHeight",
        "foreColor",
        "backColor",
        "link",
        "list",
        "justify",
        "quote",
        "emoticon",
        "image",
        "video",
        "table",
        "code",
        "splitLine",
        "undo",
        "redo",
        "alert",
      ],
      editor: "",
      fileName: "",
    };
  },
  methods: {
    init() {
      const _this = this;
      this.editor = new E(this.$refs.editor);
      this.editor.config.uploadImgShowBase64 = true; // 使用 base64 保存图片
      this.editor.config.uploadImgFromMedia = function () {
        _this.showimageEdit = true;
      };
      this.setMenus(); //设置菜单
      this.editor.config.onchange = (html) => {
        _this.$emit("change", html); // 将内容同步到父组件中
      };
      // this.editor.menus.extend("alertMenu", AlertMenu); // 配置扩展的菜单
      this.editor.config.menus = this.editor.config.menus.concat("alertMenu");
      this.editor.create(); //创建编辑器
    },

    onSelectImage(value) {
      const _this = this;
      console.log(value);
      value.forEach((element) => {
        _this.editor.cmd.do(
          "insertHTML",
          `<img src="${element}" style="max-width:100%;"/>`
        );
      });

      _this.showimageEdit = false;
    },
    setMenus() {
      // 设置菜单
      if (this.meanArray) {
        this.editor.config.menus = this.meanArray;
      } else {
        this.editor.config.menus = this.defaultMeanus;
      }
    },
    getHtml() {
      // 得到文本内容
      return this.editor.txt.html();
    },
    setHtml(txt) {
      // 设置富文本里面的值
      this.editor.txt.html(txt);
    },
  },
  mounted() {
    let that = this;
    that.$nextTick(function () {
      that.init();
    });
  },
};
</script>
<style scoped lang="scss">
.el-dialog__wrapper {
  z-index: 10006 !important;
}
</style>
