<template>
  <div>
    <el-card
      class="box-card"
      shadow="never"
      :style="{ height: cardHeight + 'px' }"
    >
      <el-row>
        <el-col :span="6">
          <el-card shadow="never" :style="{ height: tableHeight + 'px' }">
            <el-row>
              <el-col :span="22">
                <el-input placeholder="请输入内容" v-model="cateKeyword">
                </el-input>
              </el-col>
              <!-- <el-button
              type="primary"
              icon="el-icon-search"
              @click="searchCateData"
            ></el-button> -->
            </el-row>
            <el-row style="margin-top: 10px">
              <el-tree
                :data="data"
                :props="defaultProps"
                :highlight-current="true"
                :expand-on-click-node="false"
                :filter-node-method="filterNode"
                @node-click="handleNodeClick"
                ref="tree"
              >
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span>{{ node.label }}</span>
                  <span>
                    <el-dropdown trigger="click">
                      <span class="el-dropdown-link">
                        <i class="el-icon-more"></i>
                      </span>

                      <el-dropdown-menu>
                        <el-dropdown-item>
                          <div @click.stop="editData('', node, data)">
                            新增分类
                          </div>
                        </el-dropdown-item>
                        <div v-if="data.value != -1">
                          <el-dropdown-item>
                            <div @click.stop="editData(data.value, node, data)">
                              编辑分类
                            </div>
                          </el-dropdown-item>
                          <el-dropdown-item>
                            <div @click.stop="remove(node, data)">删除分类</div>
                          </el-dropdown-item>
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </span>
                </span></el-tree
              >
            </el-row>
          </el-card>
        </el-col>
        <el-col :span="18">
          <el-card
            shadow="never"
            :style="[
              { height: tableHeight + 'px' },
              { 'overflow-y': 'scroll' },
            ]"
          >
            <el-row>
              <el-button type="primary" @click="selectImage" :size="allSize">
                使用选中图片
              </el-button>

              <el-upload
                class="upload-demo"
                action="/admin/Upload/uploadImage"
                :show-file-list="false"
                multiple
                :on-success="handleSuccess"
                :before-upload="beforeUpload"
              >
                <el-button type="primary" :size="allSize">点击上传</el-button>
              </el-upload>

              <el-button type="danger" @click="delImage" :size="allSize"
                >删除图片</el-button
              >
              <el-select
                style="margin-left: 8px"
                v-model="change_cate_id"
                filterable
                clearable
                :size="allSize"
                placeholder="图片移至"
                @change="changeCate"
              >
                <template v-for="(item, index) in catesList">
                  <el-option
                    :key="index"
                    :label="item.html + item.title"
                    :value="item.value"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-col :span="8" style="float: right">
                <el-pagination
                  small
                  layout="prev, next"
                  :total="total"
                  :current-page="page"
                  :page-size="size"
                  @current-change="handleCurrentChange"
                >
                </el-pagination>
              </el-col>
            </el-row>

            <el-row
              style="margin-top: 10px"
              :style="{ 'max-height': tableHeight - 50 + 'px' }"
              v-if="images.length > 0"
              v-loading="loading"
            >
              <el-col
                :span="6"
                class="col-box"
                v-for="(item, index) in images"
                :key="index"
              >
                <div class="row-image" @click="changeImage(index)">
                  <el-image class="image" :src="item.content"> </el-image>
                  <div class="mark" v-if="item.ischange">
                    <div class="triangle"></div>
                    <div class="number">
                      {{ changeImages.indexOf(item.id) * 1 + 1 }}
                    </div>
                  </div>
                </div>
                <div>
                  <el-col :span="19" style="margin-top: 2px">
                    <el-input
                      placeholder="请输入内容"
                      v-model="item.title"
                      :size="allSize"
                    >
                    </el-input>
                  </el-col>
                </div>
              </el-col>
            </el-row>
            <el-row v-else><el-empty description="没有图片"></el-empty></el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
    <el-dialog
      title="新增内容"
      :visible.sync="showCreate"
      :close-on-click-modal="false"
      width="40%"
    >
      <el-form
        :model="cateForm"
        :rules="rules"
        ref="cateForm"
        :label-width="formLabelWidth"
      >
        <el-form-item label="标题" prop="title">
          <el-col :span="10">
            <el-input
              v-model="cateForm.title"
              autocomplete="off"
              :size="allSize"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="排序">
          <el-col :span="10">
            <el-input
              type="number"
              v-model="cateForm.sort"
              autocomplete="off"
              :size="allSize"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showCreate = false" :size="allSize">取 消</el-button>
        <el-button type="primary" @click="saveData()" :size="allSize"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
let _this;
export default {
  name: "images",
  props: {
    imagetype: {
      type: String,
    },
  },
  data() {
    return {
      change_cate_id: "",
      cateForm: {},
      rules: {
        title: [
          {
            required: true,
            message: "请填写标题",
            trigger: "blur",
          },
        ],
      },
      cateKeyword: "",
      formLabelWidth: "120px",
      showCreate: false,
      cateUrl: "Imagecate",
      allSize: "mini",
      defaultProps: {
        children: "children",
        label: "label",
      },
      data: [],
      node: "",
      nodeIndex: "",
      appendData: "",
      loading: false,
      images: [],
      imagecates_id: 0,
      changeImages: [],

      catesList: [],
      page: 1,
      size: 40,
      total: 0,
    };
  },
  watch: {
    cateKeyword(val) {
      this.$refs.tree.filter(val);
    },
  },
  computed: {
    cardHeight() {
      return window.innerHeight * 1 - 130;
    },
    tableHeight() {
      return window.innerHeight * 1 - 50 - 120;
    },
  },
  mounted() {
    _this = this;
    _this.getCateData();
    _this.getOtherData();
    _this.getImageList("");
  },
  methods: {
    handleCurrentChange(val) {
      _this.page = val;
      _this.getImageList();
    },
    changeCate(value) {
      if (_this.changeImages.length == 0) {
        _this.$notify({
          title: "温馨提示",
          type: "error",
          message: "请选择要转移的图片",
        });
        _this.change_cate_id = "";
        return false;
      }
      _this
        .postRequest("/Image/changCate", {
          ids: _this.changeImages,
          cates_id: value,
        })
        .then((resp) => {
          if (resp.code == 200) {
            _this.getImageList();
          }
          _this.$message({
            type: "success",
            message: resp.msg,
          });
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: e.message,
          });
        });
    },
    getOtherData() {
      _this
        .postRequest("/data/getList", {
          data: "imagecates-true",
        })
        .then((resp) => {
          if (resp.code == 200) {
            _this.catesList = resp.data.imagecatesList;
          }
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: e.message,
          });
        });
    },
    selectImage() {
      let value = "";
      let selectImages = this.images.filter((item) => {
        return item.ischange == true;
      });

      if (selectImages.length == 0) {
        _this.$message({
          type: "error",
          message: "请选择图片",
        });
        return false;
      }
      if (this.imagetype == "one") {
        value = selectImages[0]["content"];
      } else {
        let images = [];
        selectImages.forEach((item) => {
          images.push({ content: item.content, id: item.id });
        });
        let newimages = [];

        for (let index = 0; index < this.changeImages.length; index++) {
          const id = this.changeImages[index];
          images.forEach((item) => {
            if (item.id == id) {
              newimages.push(item.content);
            }
          });
        }
        value = newimages;
      }
      this.changeImages = [];
      this.$emit("onSelectImage", value);
      this.getImageList();
    },
    handleNodeClick(data) {
      let cate_id = data.value;
      _this.imagecates_id = cate_id == -1 ? 0 : cate_id;
      _this.getImageList();
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    getCateData() {
      _this
        .postRequest("/Imagecate/getList")
        .then((resp) => {
          if (resp.code == 200) {
            _this.data = resp.data;
          }
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: e.message,
          });
        });
    },
    saveData() {
      _this.$refs["cateForm"].validate((valid) => {
        if (valid) {
          _this.postRequest("/Imagecate/save", _this.cateForm).then((resp) => {
            if (resp.code == 200) {
              _this.$message({
                type: "success",
                message: resp.msg,
              });
              if (_this.cateForm.id) {
                _this.node[_this.nodeIndex]["label"] = _this.cateForm.title;
              } else {
                _this.append(_this.appendData, resp.data);
              }
              _this.showCreate = false;
            } else {
              _this.$message({
                type: "error",
                message: resp.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    editData(id, node, data) {
      if (id != 0) {
        const parent = node.parent;
        const children = parent.data.children || parent.data;
        const index = children.findIndex((d) => d.value === id);
        _this.nodeIndex = index;
        _this.node = children;
        _this.getInfo(id);
      } else {
        _this.cateForm = {
          title: "",
          sort: 0,
          pid: node.data.value == -1 ? 0 : node.data.value,
        };
        _this.appendData = data;
      }

      this.showCreate = true;
    },
    getInfo(id) {
      _this.getRequest("/Imagecate/read?id=" + id).then((resp) => {
        if (resp) {
          _this.cateForm = resp.data;
        }
      });
    },
    append(data, id) {
      console.log(data);

      const newChild = { value: id, label: _this.cateForm.title, children: [] };
      if (!data.children) {
        this.$set(data, "children", []);
      }
      data.children.push(newChild);
    },
    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.value === data.value);
      _this
        .$confirm("是否删除该信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _this
            .deleteRequest("/Imagecate/delete?id=" + data.value)
            .then((resp) => {
              if (resp.code == 200) {
                _this.$message({
                  type: "success",
                  message: "删除成功!",
                });

                children.splice(index, 1);
              } else {
                _this.$message({
                  type: "error",
                  message: resp.msg,
                });
              }
            });
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: e.message,
          });
        });
    },
    getImageList() {
      _this.loading = true;
      _this
        .postRequest(
          "/image/getList?page=" + _this.page + "&size=" + _this.size,
          {
            imagecates_id: _this.imagecates_id,
          }
        )
        .then((resp) => {
          _this.images = resp.data;
          _this.total = resp.count;
          _this.loading = false;
        })
        .catch((e) => {
          _this.loading = false;
          _this.$message({
            type: "error",
            message: e.message,
          });
        });
    },
    changeImage(index) {
      let ischange = _this.images[index]["ischange"];
      let id = _this.images[index]["id"];
      let imagesArray = _this.images;
      if (ischange == false) {
        _this.changeImages.push(id);
      } else {
        let index1 = _this.changeImages.findIndex((d) => d === id);
        _this.changeImages.splice(index1, 1);
      }
      _this.images = imagesArray;
      _this.images[index]["ischange"] = !_this.images[index]["ischange"];
    },

    handleSuccess(res, file) {
      let _this = this;
      if (res.code == 200) {
        _this
          .postRequest("/Image/save", {
            imagecates_id: _this.imagecates_id,
            content: res.data.url,
            title: file.name,
          })
          .then((resp) => {
            if (resp.code == 200) {
              _this.getImageList();
            }
          })
          .catch((e) => {
            this.$message({
              type: "error",
              message: e.message,
            });
          });
        _this.$message.success("上传成功!");
      } else {
        _this.$message.error(res.msg);
      }
    },
    showImage(file) {
      this.show_image = file;
      this.dialogVisible = true;
    },
    beforeUpload(file) {
      const isTypeTrue = /^image\/(jpeg|png|jpg)$/.test(file.type);
      if (!isTypeTrue) {
        this.$message.error("上传图片格式不对!");
        return;
      }
    },
    delImage() {
      this.$confirm("是否删除该信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this
            .postRequest("/image/delImage", { ids: _this.changeImages })
            .then((resp) => {
              if (resp.code == 200) {
                this.$notify({
                  title: "成功",
                  message: resp.msg,
                  type: "success",
                });
                _this.changeImages = [];
                _this.getImageList();
              } else {
                _this.$notify({
                  title: "错误",
                  type: "error",
                  message: resp.msg,
                });
              }
            });
        })
        .catch(() => {
          _this.$notify({
            title: "错误",
            type: "error",
            message: "取消删除!",
          });
        });
    },
  },
};
</script>

<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 165px;
  height: 155px;
  border: 2px #fff solid;
}
.row-image {
  position: relative;
  width: 165px;
  height: 155px;
}
.mark {
  position: relative;
  border: 2px #409eff solid;
  width: 165px;
  height: 155px;
}
.number {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  color: #fff;
}
.triangle {
  position: absolute;
  height: 0px;
  width: 0px;
  right: -30px;
  top: -30px;
  border: 30px solid #000;
  border-top-color: transparent;
  border-bottom-color: #409eff;
  border-left-color: transparent;
  border-right-color: transparent;
  transform: rotate(45deg);
}
.upload-demo {
  display: inline-block;
}
.upload-demo .el-upload--text {
  border: none;
  width: 120px;
  height: 40px;
  overflow: inherit;
}
.el-tree-node:focus > .el-tree-node__content {
  background-color: #3196ef !important;
  color: #fff !important;
}
/*节点失焦时的背景颜色*/
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #3196ef !important;
  color: #fff !important;
}
.el-tree-node:focus > .el-tree-node__content .el-dropdown-link {
  color: #fff !important;
}
/*节点失焦时的背景颜色*/
.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content
  .el-dropdown-link {
  color: #fff !important;
}
</style>
